import React, { Component } from "react";

class IconUser extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                role="presentation"
            >
                <path
                    d="M24 18.4729C24 18.4729 22 16.5781 16.5 16.5781C11 16.5781 9 18.4729 9 18.4729V26.9992H24V18.4729Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                />
                <path
                    d="M16.447 13C18.6484 13 20.433 11.2091 20.433 9C20.433 6.79086 18.6484 5 16.447 5C14.2455 5 12.4609 6.79086 12.4609 9C12.4609 11.2091 14.2455 13 16.447 13Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                />
            </svg>
        );
    }
}

export default IconUser;
