import { useCallback, useEffect } from "react";

const useKeyDown = (targetKey: string, onKeyDown: () => void, condition: boolean) => {
    const onEscape = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === targetKey) {
                onKeyDown();
            }
        },
        [targetKey]
    );

    useEffect(() => {
        if (condition) {
            window.addEventListener("keydown", onEscape);
        }

        return () => window.removeEventListener("keydown", onEscape);
    }, [condition]);
};

export default useKeyDown;
